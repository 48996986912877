const runOnPage = 'body.category';
const triggerInfiniteScrollElmt = '.pagerNav.end_pagi .button.primary';
const seeMoreContainerElmt = '.pagerNav.end_pagi';
const pageLoaderElmt = '.pagerNav.end_pagi #page_loader';
const paginationElmt = '.pagination';

const isMobilePagination = document.body.classList.contains('mobile');
const runOnDevice = isMobilePagination ? 'mobile' : 'desktop';

const paginationConfig = {
    mobile: {
        headerWrapperElmt: '.banner_wrapper',
        itemContainerElmt: '#list_item .item_container',
        pushElmt: '#scroll_items .list_item > div',
        resetElmt: 'li.menu_item a.bloc_lnk.trigger_loader',
        categoryFormId: '#filters_form',
        paginationWrapperElmt: '#pagination_content',
        seeMoreElmt: '.pagerNav.end_pagi .loader_scroll_new',
        deviceLoadDelay: 800,
        sliderRangeElmt: '#slider-range',
        sliderRangeMinTextElmt: '#slider-range span.ui-slider-handle:first',
        sliderRangeMaxTextElmt: '#slider-range span.ui-slider-handle:last',
    },
    desktop: {
        headerWrapperElmt: '#site_head_wrap',
        itemContainerElmt: '#list_item .item',
        pushElmt: '#list_item .item',
        resetElmt: '.menu_top_wrapper a',
        categoryFormId: '#filter_sticky',
        paginationWrapperElmt: '.pager_wrapper',
        seeMoreElmt: '.pagerNav.end_pagi .loader_scroll',
        deviceLoadDelay: 400,
        sliderRangeElmt: '#slider-range',
        sliderRangeMinTextElmt: '#slider-range span.min',
        sliderRangeMaxTextElmt: '#slider-range span.max',
    },
}[runOnDevice];

const {
    headerWrapperElmt,
    itemContainerElmt,
    pushElmt,
    resetElmt,
    categoryFormId,
    paginationWrapperElmt,
    seeMoreElmt,
    deviceLoadDelay,
    sliderRangeElmt,
    sliderRangeMinTextElmt,
    sliderRangeMaxTextElmt,
} = paginationConfig;

// Check if element is in view port
function isInViewport(element) {
    const elementTop = $(element).offset().top;
    const elementBottom = elementTop + $(element).outerHeight();
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

// Activate/Desactivate infinite scroll
function initInfiniteScroll(activate = false) {
    if (activate == true) {
        window.addEventListener('scroll', handleScroll);
        window.hasScrollListener = true;
        $(seeMoreElmt).css({ opacity: 0 });
        sessionStorage.setItem('infiniteScroll', true);
    } else {
        window.removeEventListener('scroll', handleScroll);
        window.hasScrollListener = false;
        sessionStorage.setItem('infiniteScroll', false);
    }
}

// Run when inifite scroll activated to check if first item or "See all" button is in view port to trigger next/previous page load
function handleScroll() {
    const isLoading = parseInt($('#is_loading').val());
    const maxItems = parseInt($('#totalElems').val());
    const productLoaded = $(itemContainerElmt + ':not(".push")').length;
    const button = $(triggerInfiniteScrollElmt);
    const firstItem = $(itemContainerElmt).first(); // Get first item on the list
    const nextTopPage = parseInt($('#top_page').val()) - 1; // Get next top page to load
    const nextBottomPage = parseInt($('#bottom_page').val()) + 1; // Get bottom page to load

    if (isLoading == 0 && productLoaded < maxItems) {
        // Continue infinite scroll if loading action complete and not all items loaded
        if (isInViewport(firstItem) && nextTopPage > 0) {
            // If first item is visible and top page not 1, load next top page
            paginationGoToPage(nextTopPage, 'top');
        } else if (isInViewport(button)) {
            // If "See all" button visible, load next page
            paginationGoToPage(nextBottomPage, 'bottom');
        }
    } else if (productLoaded == maxItems) {
        // Stop inifinite scroll as all items loaded
        initInfiniteScroll(false);
        sessionStorage.setItem('infiniteScroll', true); // But keep session active for page reload or accessing page from back button
    }
}

// Goes to given page
// Works with pager in shop districts
function paginationGoToPage(page = 1, scrollDirection) {
    const productLoaded = $(itemContainerElmt + ':not(".push")').length;
    const maxItems = parseInt( $( '#totalElems' ).val() );
    const totalPages = Math.ceil(maxItems / parseInt($('#nb_prod').val()));
    const isLastPage = page - 1 >= totalPages;
    const allProductLoaded = productLoaded == maxItems ? true : false;

    if (scrollDirection !== undefined && (isLastPage || allProductLoaded)) {
        // Stop infinite scroll if last page reached or all items loaded on page
        initInfiniteScroll(!allProductLoaded);
        return false;
    }

    if (scrollDirection == 'top') {
        $(pageLoaderElmt).show();
        $('#top_page').val(page);
        initInfiniteScroll(true);
    } else if (scrollDirection == 'bottom') {
        $(pageLoaderElmt).show();
        $('#bottom_page').val(page);
        initInfiniteScroll(true);
    } else {
        // Both field uses same page reference
        $('#top_page, #bottom_page').val(page);

        // "See all" button must be visible
        $(seeMoreElmt).fadeTo(100, 1);

        // Remove infinite scroll eventLister when normal pagination
        initInfiniteScroll(false);

        setTimeout(() => {
            // Scroll to top
            if ($(runOnPage).length) {
                $(document).scrollTop(0);
            }
        }, 1000);
    }

    const nextPage = parseInt(page) - 1;
    const fromScrollTop = scrollDirection === 'top';
    const fromScrollBottom = scrollDirection === 'bottom';

    if (runOnDevice === 'mobile') {
        const type = $('#type_tri').val();
        const idObj = getCurrentIdRayon();

        if (from_type == 'product') {
            generateNewBlocProd(type, idObj, nextPage, null, null, fromScrollBottom, fromScrollTop);
        } else {
            generateNewBlocSearch($('#nb_prod').val(), nextPage, null, fromScrollBottom, fromScrollTop);
        }
    } else {
        // Si on est sur la page de recherche
        if ($('#search_page').length > 0) {
            generateNewBlocSearch(nextPage, null, null, null, null, fromScrollBottom, fromScrollTop, fromScrollBottom);
        } else {
            generateNewBlocProd(nextPage, null, null, null, null, fromScrollBottom, fromScrollTop, fromScrollBottom);
        }

        /* Filters Rayon */
        lionBarOnFilter();
    }
}

function paginate( page = 1 ) {
    const $form = $( categoryFormId );

    if (!$form.length) return;

    const maxItems = parseFloat($form.find('#totalElems').val());
    const nb_prod = parseFloat($form.find('#nb_prod').val());
    const nb_total_page = Math.ceil(maxItems / nb_prod);
    const pagination = $(paginationWrapperElmt);

    $(seeMoreElmt).show();

    $.ajax({
        url: `${path_relative_root}ajax_reload_pagination.php`,
        type: 'GET',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page,
        },
        success: (res) => {
            pagination.html(res);

            if (res == '') {
                // Hide "See all" button as only one page available
                $(seeMoreElmt).css({ opacity: 0 });
            }
        },
        complete: () => {
            $(seeMoreContainerElmt).css({ opacity: 1 }); // Restore opacity for item autoscroll
            $(paginationElmt).attr('data-totalpage', nb_total_page);

            if (page < nb_total_page) {
                $(paginationElmt).attr('data-pagenum', page + 1);
            }

            if (runOnDevice === 'mobile') {
                $(itemContainerElmt).toggleClass('full', !$('#twoProducts').hasClass('actif'));
                $('#push_rayon_1').toggle($('#twoProducts').hasClass('actif'));
            } else {
                $('.items_wrapper').toggleClass('view_per_three', $('aside.views #viewPerThree').hasClass('is-active'));
            }

            setTimeout(() => {
                $('.productSwiper').each((index, container) => {
                    const $container = $(container);

                    if ($container.find('.productSwiper').length === 0 &&
                        $container.find('.swiper-wrapper').length &&
                        $container.find('.swiper-slide').length > 1) {
                        new Swiper(container, {
                            slidesPerView: 1,
                        });
                    }
                });
            }, 500);
        },
    });
}

function addClickTrigger(actualPage) {
    $(itemContainerElmt).each(function () {
        const $this = $(this);
        const dataAnchor = $this.find('.anchor_ref').attr('id');
        const onclickValue = $this.attr('onClick') || '';

        if (dataAnchor && !onclickValue.includes('saveItemData')) {
            $this.attr('onClick', `saveItemData('${actualPage}', '${dataAnchor}');${onclickValue}`);
        }
    });
}

function saveItemData(dataPage, dataAnchor) {
    updateUrlParameter('page', dataPage);
    updateUrlParameter('anchor', dataAnchor);
}

function updateUrlParameter(param, value) {
    // Create a URL object from the current window location
    const url = new URL(window.location.href);

    // Use URLSearchParams to modify the query parameters
    const searchParams = url.searchParams;

    if (param === 'anchor') {
        // Construct new URL with anchor
        const newUrl = window.location.href.split('#')[0] + '#' + value;

        // Update the URL without reloading
        history.pushState(null, '', newUrl);
    } else if (param === 'page' && value === 1) {
        // Remove the specified parameter
        url.searchParams.delete(param);

        // Update the browser's URL without reloading the page
        window.history.replaceState({}, document.title, url.toString());
    } else if (searchParams.has(param) || sessionStorage.getItem('infiniteScroll') === 'true') {
        // Set or update the parameter
        searchParams.set(param, value);

        // Update the browser's address bar without reloading the page
        window.history.replaceState({}, '', url.toString());
    }
}

function initAllFilters() {
    // Restore price filter
    const url = new URL( window.location.href );
    const searchParams = url.searchParams;
    let triggerReload = false;

    if (searchParams) {
        if (searchParams.has('price')) {
            const min_price = $('input[name="initial_min_price"]').val();
            const max_price = $('input[name="initial_max_price"]').val();

            $(sliderRangeMinTextElmt).html(min_price);
            $(sliderRangeMaxTextElmt).html(max_price);

            $('input[name="price_min"]').val(min_price);
            $('input[name="price_max"]').val(max_price);

            var $slider = $(sliderRangeElmt);
            $slider.slider('values', 0, min_price);
            $slider.slider('values', 1, max_price);

            url.searchParams.delete('price');
        }

        if (searchParams.has('order')) {
            if (runOnDevice === 'desktop') {
                $('a.tri_price.actif').removeClass('actif');
                $('input#choice_tri').val(0);
            } else if (runOnDevice === 'mobile') {
                $('#wrapper_price_filtre input[name="tri"]:checked').trigger('click');
            }

            url.searchParams.delete('order');
            triggerReload = true;
        }

        window.history.replaceState({}, '', url.toString());
    }

    // Restore filter elements
    const form = (this instanceof HTMLFormElement) ? this : document.getElementById(categoryFormId.replace('#', ''));
    const ctrl = Array.prototype.slice.call(form.elements, 0);
    const excludedNames = ['couleur', 'taille', 'charact', 'tri'];

    ctrl.forEach((elm) => {
        if (elm.type === 'checkbox' || elm.type === 'radio') {
            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.click();

                if (!excludedNames.includes(elm.name)) {
                    elm.value = 0;
                }

                elm.checked = false;
            }
        }
    });

    // Restore filter menu
    if (runOnDevice === 'desktop') {
        setTimeout(filterTrigger, 1000);
    } else if (runOnDevice === 'mobile') {
        toggleMenu('filters', 'right');
    }

    if (triggerReload) {
        paginationGoToPage.call(form, 1);
    }
}

// Save infinite scroll last session state on page load
let initScrollToState = sessionStorage.getItem('infiniteScroll');

$(document).ready(function () {
    if ($(runOnPage).length) {
        const productLoaded = $(itemContainerElmt + ':not(".push")').length;
        const maxItems = parseInt($('#totalElems').val());

        if (productLoaded < maxItems) {
            // "See all" button must be visible when loading page for first time by default
            $(seeMoreElmt).fadeTo(100, 1);
        }

        // Handle "See all" click to trigger infinite scroll
        $(seeMoreElmt).on('click', function () {
            $(this).addClass('loading');

            const productLoaded = $(itemContainerElmt + ':not(".push")').length;
            const nextPage = parseInt($('#bottom_page').val()) + 1;

            if (productLoaded < maxItems) {
                paginationGoToPage(nextPage, 'bottom');
            }
        });

        // Store clicked product page reference
        addClickTrigger($('#bottom_page').val());

        fixItemCountOnPage();

        setTimeout(() => {
            const anchor = window.location.hash;

            if (anchor) {
                // Smooth scroll to the target element
                const targetElement = $(anchor);

                if (targetElement.length) {
                    const headerHeight = $(headerWrapperElmt).outerHeight(); // Adjust selector as needed for the header
                    const targetPosition = $(targetElement).offset().top - (headerHeight + 20); // Calculate target position

                    // Remove the anchor from the URL after scrolling
                    history.replaceState(null, null, ' '); // This removes the hash

                    $('html, body').animate({
                            scrollTop: targetPosition,
                    }, 300); // Duration of the scroll
                }
            }
        }, deviceLoadDelay);

        if (initScrollToState === 'true') {
            // Restore infinite scroll if previously present
            setTimeout(() => {
                initInfiniteScroll(true);
            }, 1000);
        }
    }

    setTimeout(() => {
        $(resetElmt).on('click touchstart', () => {
            if (!$(this).attr('onClick')) {
                // Disable scroolToProduct function
                eraseCookie('p_id');
                // Disable infinite scroll if page 1 is loaded else leave last choice on
                initInfiniteScroll(false);
            }
        });
    }, deviceLoadDelay);
});

function fixItemCountOnPage() {
    const max_loop = parseInt($('#nb_prod_default').val());
    let push_count = 0;

    $(pushElmt).each(function (index, elmt) {
        if (index < max_loop) {
            if ((runOnDevice === 'desktop' && $(elmt).hasClass("push") && $(elmt).is(':visible')) || 
                (runOnDevice === 'mobile' && $(elmt).hasClass("show_push_multi_product"))) {
                push_count++;
            }
        }
    });

    $('#push_count').val(push_count);
}
