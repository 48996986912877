// creer l'instanciation de l'objet XHR qu'inporte le navigateur
function getXMLHttpRequest() {
    var xhr = null;

    if (window.XMLHttpRequest || window.ActiveXObject) {
        if (window.ActiveXObject) {
            var tableau_xhr_activeX = [
                "Msxml2.XMLHTTP.6.0",
                "Msxml2.XMLHTTP.3.0",
                "Msxml2.XMLHTTP",
                "Microsoft.XMLHTTP",
            ];

            for (var value in tableau_xhr_activeX) {
                try {
                    xhr = new ActiveXObject(tableau_xhr_activeX[value]);
                    break;
                } catch (error) {}
            }
        } else {
            xhr = new XMLHttpRequest();
        }
    } else {
        return null;
    }
    return xhr;
}

// Permet le non rafraichissement d'une page en exploitant l'objet XHR
function ajax_file(fichier) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else return false;
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return xhr_object.responseText;
    else return false;
}

function ajax_file_post(url, params) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

    xhr_object.open("POST", url, false);
    xhr_object.setRequestHeader(
        "Content-type",
        "application/x-www-form-urlencoded"
    );
    xhr_object.send(params);

    if (xhr_object.readyState == 4 && xhr_object.status == 200) {
        return xhr_object.responseText;
    }
}

function ajax_file_XML(fichier) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else return false;
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return xhr_object.responseXML;
    else return false;
}

var global_id_transporteur = '';
function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    // on définir globalement l'id transporteur pour l'utiliser dans la création de l'etiquette
    global_id_transporteur = idTransporteur;

    setTimeout(function(){
        OverlayScrollbars(document.querySelector('#return_products_form .mainWrapper'), {});
    }, 300);

    openMultiShad("popup_numero_retour");
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, id_transporteur) {

    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form').serialize();
        data += '&idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour;
    } else {
        data = 'idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour;
    }

    $.ajax({
        url : path_relative_root + 'ajax_create_etiquette.php',
        type : 'post',
        data : data,
        success : function (res) {
            if ( res.substr(0,5) == 'false' ) {
                alert( ajax_file( path_relative_root + 'ajax_sprintf.php?arg1=js_error_return_label ' ) );
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}

function createEtiquetteRetour(idOrder, multiTransp,idOrderTransp) {

    var has_errors = false;
    $(".return_quantity").each(function() {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0  && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {
        let data = $('#return_products_form').serialize();
        data += '&idOrder='+idOrder+'&multiTransp='+multiTransp;

        $.ajax({
            url : path_relative_root + 'ajax_create_etiquette.php',
            type : 'post',
            data : data,
            success : function (res) {
                location.reload();
            }
        });
    }
}

// --------------------------------------------------------------------------------

// Transformation XML en tableau
function traiteXmlQte(xmlDoc) {
    var options = xmlDoc.getElementsByTagName("option");
    var optionsListe = new Array();
    for (var i = 0; i < options.length; ++i) {
        var optionsListe2 = new Array();

        var id = options.item(i).getElementsByTagName("id");
        var label = options.item(i).getElementsByTagName("label");
        var color = options.item(i).getElementsByTagName("color");
        optionsListe2.push(
            id.item(0).firstChild.data,
            label.item(0).firstChild.data,
            color.item(0).firstChild.data
        );
        optionsListe.push(optionsListe2);
    }
    return optionsListe;
}

// Affiche la liste des quantites d'un produit (HTML Tag select)
function showQteListe(elementID, idProduit, parentPath, suff) {
    var couleurs_id = "couleurProd";
    var tailles_id = "tailleProd";

    if (elementID != "liste_qte") {
        couleurs_id += "_" + idProduit;
        tailles_id += "_" + idProduit;
    }

    var listQte = $("#" + elementID);
    var alternative_listQte = $("#liste_qte_spe");

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html(
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' +
                    parentPath +
                    'img/sablier_2.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>'
            );

            var couleurs = $("#" + couleurs_id).length
                ? $("#" + couleurs_id).val()
                : "none";
            var tailles = $("#" + tailles_id).length
                ? $("#" + tailles_id).val()
                : "none";
        } else {
            alternative_listQte.html(listQte.html());

            var couleurs = "none";
            var tailles = "none";
        }

        var response = ajax_file(
            parentPath +
            create_link('ajax_liste_quantity') + "?idprod=" +
                idProduit +
                "&idcouleur=" +
                couleurs +
                "&idtaille=" +
                tailles +
                "&produit_lot_nb=" +
                $("#produit_lot_nb").val() +
                "&suff=" +
                (suff || "")
        );

        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            var select_stock = $("#qteProd");
            var regexp_epuise = /Stock /;
            var txt_stock = $("#qteProd>option:selected").text();

            if (
                select_stock.length > 0 &&
                txt_stock.match(regexp_epuise) != null
            ) {
                $("#add_alert").show();
                $("#add_basket").hide();
            } else {
                $("#add_alert").hide();
                $("#add_basket").show();
            }
        }
    }
}

function showQteListeGamme(elementID, idProduit, parentPath) {
    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById(
        "liste_qte_spe_" + idProduit
    );

    if (listQte || alternative_listQte) {
        if (listQte) {
            if (document.getElementById("couleurProd_" + idProduit))
                var couleurs = document.getElementById(
                    "couleurProd_" + idProduit
                ).value;
            if (document.getElementById("tailleProd_" + idProduit))
                var tailles = document.getElementById("tailleProd_" + idProduit)
                    .value;
            listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        } else {
            var couleurs = "none";
            var tailles = "none";
            alternative_listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        }

        var string_params = "";
        if ($("#produit_lot_nb_" + idProduit).val() != undefined)
            string_params +=
                "&produit_lot_nb=" + $("#produit_lot_nb_" + idProduit).val();

        if ($("#qte_default_" + idProduit).val() != undefined)
            string_params +=
                "&qte_default=" + $("#qte_default_" + idProduit).val();

        var response = ajax_file(
            parentPath +
                "ajax_liste_qte_alerte.php?idprod=" +
                idProduit +
                "&idcouleur=" +
                couleurs +
                "&idtaille=" +
                tailles +
                string_params
        );

        if (response) {
            if (listQte) listQte.innerHTML = response;
            else alternative_listQte.innerHTML = response;
        }
    }
}

function showQteListeAssoc(elementID, idProduit, parentPath) {
    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById(
        "liste_qte_spe_" + idProduit
    );
    listQte.innerHTML =
        '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
        parentPath +
        'img/sablier.gif" />Chargement...</td></tr></table>';
    var couleurs = document.getElementById("couleurProdAssoc_" + idProduit);
    var tailles = document.getElementById("tailleProdAssoc_" + idProduit);
    if (listQte || alternative_listQte) {
        if (listQte) {
            var couleurs = document.getElementById("couleurProd_" + idProduit)
                .value;
            var tailles = document.getElementById("tailleProd_" + idProduit)
                .value;
            listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        } else {
            var couleurs = "none";
            var tailles = "none";
            alternative_listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        }

        //if (couleurs.value != "" && tailles.value != "") {
        var response = ajax_file(
            parentPath +
            create_link('ajax_liste_quantity') + "?idprod=" +
                idProduit +
                "&idcouleur=" +
                couleurs.value +
                "&idtaille=" +
                tailles.value
        );
        if (response) {
            // On ex�cute deux fois car replace ne remplace qu'une seule fois alors que nous avons deux occurences
            response = response.replace("qteProd_", "qteProdAssoc_");
            response = response.replace("qteProd_", "qteProdAssoc_");
            if (listQte) listQte.innerHTML = response;
            else alternative_listQte.innerHTML = response;
        }
        //}
        //else
    }
}

// Affiche la liste des auantites d'un produit (HTML Tag select)
function showQteListePanier(elementID, idProduit, i, paniervirtuelId) {
    var listQtePanier = document.getElementById(elementID);

    var couleurs = document.getElementById("couleurProd" + i);
    var tailles = document.getElementById("tailleProd" + i);

    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;

    if (listQtePanier) {
        var response = ajax_file(
            "ajax/ajax_liste_qte_panier.php?idprod=" +
                idProduit +
                "&idcouleur=" +
                idCouleur +
                "&idtaille=" +
                idTaille +
                "&i=" +
                i +
                "&panierId=" +
                paniervirtuelId
        );
        if (response) listQtePanier.innerHTML = response;
    }
}

function updatePanier(idProduit, i, paniervirtuelId) {
    var couleurs = document.getElementById("couleurProd" + i);
    var tailles = document.getElementById("tailleProd" + i);
    var qte = document.getElementById("qteProd" + i);

    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;

    ajax_file(
        "ajax/ajax_upd_panier.php?idprod=" +
            idProduit +
            "&idcouleur=" +
            idCouleur +
            "&idtaille=" +
            idTaille +
            "&qte=" +
            qte.value +
            "&panierId=" +
            paniervirtuelId
    );
}

function newUpdatePanier(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet,
    idElTaille,
    lastTaille,
    idElCouleur,
    lastCouleur,
    couleurProd,
    tailleProd
) {
    if (i != "") {
        i = "_" + i;

        prixU = $("#prixU" + i).val();
    }

    var couleurs = (typeof couleurProd != 'undefined' && couleurProd != null) ? couleurProd : $("#couleurProd" + i);
    var tailles = (typeof tailleProd != 'undefined' && tailleProd != null) ? tailleProd : $("#tailleProd" + i);
    var res = "";
    var html_err = "";
    var flagError = false;

    if ($("#lightbox_achat_express").length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err +=
        '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if (tailles instanceof HTMLInputElement == false && tailles && tailles.val() == "" || tailles instanceof HTMLInputElement && tailles.value == "") {
        $("#sizeerror").slideDown();

        return false;
    } else if (couleurs && couleurs.val() == "") {
        res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_couleur");
        flagError = true;
    } else if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value == ""
    ) {
        res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_quantity");
        flagError = true;
    }

    if (flagError) {
        alert(res);

        return false;
    } else {
        var qte = 0;

        if ($("#qteProd" + i)) {
            qte = $("#qteProd" + i).val();
        }

        var idCouleur = (couleurs instanceof HTMLInputElement) ? couleurs.value : couleurs.val();
        var idTaille = (tailles instanceof HTMLInputElement) ? tailles.value : tailles.val();

        if (idCouleur === undefined) {
            idCouleur = idElCouleur;
        }

        if (idTaille === undefined) {
            idTaille = idElTaille;
        }

        var array_response = [];
        var response = ajax_file(
            path_web +
                "ajax_upd_panier.php?prixu=" +
                prixU +
                "&idprod=" +
                idProduit +
                "&idcouleur=" +
                idCouleur +
                "&idtaille=" +
                idTaille +
                "&qte=" +
                qte +
                "&panierId=" +
                paniervirtuelId
        );

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match("^[0-9]")) {
                if (designation == 1) {
                    designation += " lot de";
                } else {
                    designation += " lots de";
                }
            }

            designation += " " + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    designation +=
                        " - Taille " +
                        document.getElementById(idElTaille).options[
                            document.getElementById(idElTaille).selectedIndex
                        ].text;
                } else if (lastTaille != "") {
                    designation += " - Taille " + lastTaille;
                }
            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {
                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                    designation +=
                        " - " +
                        $('[name="' + idElCouleur + '"]:checked')
                            .get(0)
                            .nextElementSibling.title.trim();
                } else if (lastCouleur != "") {
                    designation += " - " + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == "false") {
                $("#size_" + idTaille).addClass("disabled");
                alert(Translator.translate("product_no_stock_2"));
                return false;
            }

            if (typeof(pintrk) != 'undefined') {
                // AddToCart
                pintrk('track', 'addtocart', {
                    value: prixU,
                    order_quantity: qte,
                    currency: 'EUR'
                });
            }
        }

        return array_response;
    }
}

//Affiche la liste des questions appropri�es au service demand�
function show_question(elementID, fieldID, idService, parentPath) {
    var list_question = document.getElementById(elementID),
        field = document.getElementById(fieldID);

    clearTimeout(time_out);

    if (list_question) {
        $.ajax({
            url: path_relative_root + "ajax_list_question.php",
            type: "post",
            data: {idService: idService},
            success: function(res) {
                list_question.innerHTML = res;
                field.innerHTML = "";
            },
        });
    }
}

// Affiche les champs appropri�es au service et � la question demand�
function show_fields(elementID, idService, idQuestion, parentPath) {
    var list_field = document.getElementById(elementID);

    clearTimeout(time_out);

    if (list_field) {
        var response = ajax_file(
            parentPath +
                "ajax_field_contact.php?idService=" +
                idService +
                "&idQuestion=" +
                idQuestion
        );

        $(".contact-beware a").attr(
            "href",
            parentPath +
                create_link("connexion_login") +
                "?from=contact&id_service=" +
                idService +
                "&id_question=" +
                idQuestion
        );

        if (response) {
            list_field.innerHTML = response;

            $("#produit_ref_input").bind("keyup", function() {
                // Recuperation de tous les produit
                getAllProduct("produit_ref");
            });

            $("#produit_nom_input").bind("keyup", function() {
                // Recuperation de tous les produit
                getAllProduct("produit_nom");
            });

            tabError = [];

            tabError[tabError.length] = [
                "radio",
                "optin",
                "message",
            ];

            tabError[tabError.length] = [
                "alpha",
                "contactNom",
                "contact_field_nom",
            ];
            tabError[tabError.length] = [
                "alpha",
                "contactPrenom",
                "contact_field_prenom",
            ];
            tabError[tabError.length] = [
                "mail",
                "contactMail",
                "contact_field_mail",
            ];

            if (idService == 7) {
                tabError[tabError.length] = [
                    "alphanum",
                    "contactAdresse",
                    "contact_field_adresse",
                ];
                tabError[tabError.length] = [
                    "alphanum",
                    "contactCP",
                    "contact_field_cp",
                ];
                tabError[tabError.length] = [
                    "alphanum",
                    "contactVille",
                    "contact_field_ville",
                ];
                tabError[tabError.length] = [
                    "alphanum",
                    "contactPays",
                    "contact_field_pays",
                ];
            } else {
                if (idService == 2 && idQuestion == 5) {
                    tabError[tabError.length] = [
                        "alphanum",
                        "contactRefProduit",
                        "contact_field_ref_produit",
                    ];
                }

                if (idService == 14) {
                    if (idQuestion == 3) {
                        tabError[tabError.length] = [
                            "alphanumadresse",
                            "contactAdresse",
                            "contact_field_adresse",
                        ];
                        tabError[tabError.length] = [
                            "phone",
                            "contactNumPortable",
                            "contact_field_num_portable",
                        ];
                        tabError[tabError.length] = [
                            "alphanum",
                            "contactCP",
                            "contact_field_cp",
                        ];
                        tabError[tabError.length] = [
                            "alphanumadresse",
                            "contactVille",
                            "contact_field_ville",
                        ];
                        tabError[tabError.length] = [
                            "select_date",
                            "garantieDateAchat",
                            "date_achat",
                        ];
                        tabError[tabError.length] = [
                            "prix",
                            "contactPrix",
                            "prix",
                        ];
                        tabError[tabError.length] = [
                            "alphanum",
                            "id_produit_ref",
                            "date_achat",
                        ];

                        /*
                        $("#telephoneFake").intlTelInput({
                            utilsScript: parentPath+'js/intlTelInputUtils.js'
                        });
                        */

                        // Date Achat validation
                        $("#btn_contact").click(function(e) {
                            e.preventDefault();

                            if (
                                document.getElementById("garantieDateAchatM")
                                    .value === "" ||
                                document.getElementById("garantieDateAchatA")
                                    .value === "" ||
                                document.getElementById("garantieDateAchatJ")
                                    .value === ""
                            ) {
                                $("#garantieDateAchatLabel").addClass(
                                    "labelErr"
                                );
                            } else {
                                $("#garantieDateAchatLabel").removeClass(
                                    "labelErr"
                                );
                                $("#garantieDateAchatLabel")
                                    .siblings(".img_error")
                                    .remove();
                            }

                            if ($("#id_produit_ref").val() == "") {
                                $("#produit_ref_input").attr(
                                    "class",
                                    "inputErr"
                                );
                                $("#produit_nom_input").attr(
                                    "class",
                                    "inputErr"
                                );
                                $("#id_produit_refLabel").attr(
                                    "class",
                                    "labelErr"
                                );
                                $("#produit_nom_inputLabel").attr(
                                    "class",
                                    "labelErr"
                                );

                                $("#id_produit_ref").after(
                                    '<img style="margin: 2px 0 0 10px" src="/wshop-front/rodier.com/htdocs/img/picto_panneau.png" alt="" class="img_error">'
                                );
                                $("#produit_nom_input").after(
                                    '<img style="margin: 2px 0 0 10px" src="/wshop-front/rodier.com/htdocs/img/picto_panneau.png" alt="" class="img_error">'
                                );
                            } else {
                                $("#produit_ref_input").attr(
                                    "class",
                                    "inputForm"
                                );
                                $("#produit_nom_input").attr(
                                    "class",
                                    "inputForm"
                                );
                                $("#id_produit_refLabel").attr("class", "");
                                $("#produit_nom_inputLabel").attr("class", "");
                            }

                            if ($("#contactMagasin").val() == "") {
                                $("#contactMagasin").after(
                                    '<img style="margin: 2px 0 0 10px" src="/wshop-front/rodier.com/htdocs/img/picto_panneau.png" alt="" class="img_error">'
                                );
                                $("#contactMagasinLabel").addClass("labelErr");
                            }
                        });
                    } else if (idQuestion == 2) {
                        var content = ajax_file(
                            "ajax_field_active_garantie.php"
                        );

                        if (content) {
                            $("#select_garantieNomProduit").append(content);
                            $("#field_garantieNomProduit").removeClass("cache");
                            $("#field_error_garantieProduit").addClass("cache");
                            $("#field_photo").addClass("cache");
                            $("#field_error_garantieProduit td").empty();
                        } else {
                            $("#field_garantieNomProduit").addClass("cache");
                        }
                    }
                }
            }

            if (!(idService == 3 && idQuestion == 2) && idService != 7) {
                tabError[tabError.length] = [
                    "no_empty",
                    "contactMessage",
                    "contact_field_message",
                ];
            }
        } else {
            list_field.innerHTML = "";
        }
    }
}

function show_field_form(parentPath) {
    var list_field = document.getElementById("field");
    clearTimeout(time_out);
    if (list_field) {
        var response = ajax_file(
            parentPath + "ajax_field_contact.php?idService=7&idQuestion=1"
        );
        if (response) {
            list_field.innerHTML = response;

            tabError = new Array();
            tabError[0] = new Array("alpha", "contactNom", "contact_field_nom");
            tabError[1] = new Array(
                "alpha",
                "contactPrenom",
                "contact_field_prenom"
            );
            tabError[2] = new Array(
                "mail",
                "contactMail",
                "contact_field_mail"
            );
            tabError[3] = new Array(
                "alphanum",
                "contactAdresse",
                "contact_field_adresse"
            );
            tabError[4] = new Array(
                "alphanum",
                "contactCP",
                "contact_field_cp"
            );
            tabError[5] = new Array(
                "alphanum",
                "contactVille",
                "contact_field_ville"
            );
            tabError[6] = new Array(
                "alphanum",
                "contactPays",
                "contact_field_pays"
            );
        } else list_field.innerHTML = "";
    }
}

// Affiche les produits de la commande appropri�es au service litige // Contact
function show_product_commande(idCommande, parentPath) {
    var list_field = document.getElementById("field_produit_commande");
    if (idCommande) {
        var response = ajax_file(
            parentPath +
                "ajax_field_product_commande.php?idCommande=" +
                idCommande
        );
        if (response) {
            list_field.innerHTML = response;
            $("#produitCommande").removeClass("cache");
        }
    } else {
        list_field.innerHTML = "";
        $("#produitCommande").addClass("cache");
        $("#field_message").addClass("cache");
        $("#field_photo").addClass("cache");
        $("#field_send").addClass("cache");
    }
}

/**
 * Pour charger tous les produits sur une page
 * @author
 * @since
 *
 * @param  String           type       [description]
 * @param  Number           idObj      [description]
 * @param  Number           page       [description]
 * @param  Number           nb_product [description]
 * @param  HTMLElement      btn        le bouton sur lequel on a cliqué pour charger les element (si undefined pas de probleme)
 *
 * @return void
 */

var bloc_prod_xhr;

function onColorCheckChange(evt) {
    if (this.checked && !evt.ctrlKey) {
        $(this.closest(".selected_options"))
            .find("input[type=checkbox]:checked")
            .not(this)
            .prop("checked", false);
    }
}

function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop, isFrom) {
    var form =
        this instanceof HTMLFormElement ? this : document.getElementById("filter_sticky"),
        page = page !== undefined ? page : form.getValue("page", parseFloat),
        type = type || form.getValue("type"),
        idObj = idObj || form.getValue("idObj"),
        nb_product = nb_product || form.getValue("nb_prod", parseFloat),
        output = {},
        is_from_page = false,
        key,
        navigationTypeIsScroll = isFrom ? true : false,
        total_element = form.getValue("totalElems", parseFloat),
        nb_prod = form.getValue("nb_prod", parseFloat),
        nb_total_page = Math.ceil(total_element / nb_prod);

    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky', true, '', navigationTypeIsScroll);

    if (fromScrollTop || fromScrollBottom) {
        $('#is_loading').val('1');
    }

    $('#block_selected_filter').html('');

    if (form.getValue('price_min', parseFloat) != $('input[type=hidden][name=initial_min_price]').val() || form.getValue('price_max', parseFloat) != $('input[type=hidden][name=initial_max_price]').val()) {
		addFilter(form.getValue('price_min', parseFloat) + ' - ' + form.getValue('price_max', parseFloat), 'priceRange');
    }

    for (key in output) {
        if (output.hasOwnProperty(key)) {
            if (output[key].constructor === Array) {

                for (key2 in output[key]) {
                    var name = $('.'+key+'_'+output[key][key2]).attr('title');
					var id= $('.'+key+'_'+output[key][key2]).attr('class');
                    addFilter(name,id);
                }
            }
        }
    }

    if ($('#block_selected_filter').html()!='') {
        $('#block_selected_filter').append('<span class="remove_filter">'+ Translator.translate('remove_all_filters')+'</span>');
        $(".remove_filter").on("click", deleteAllFilter);
    }

    // Add misc values to output object
    output.page = fromScrollBottom ? $(".item:not(.push)").length : form.page.value - form.getValue('push_count', parseInt);
    output.page = (output.page > 0) ? output.page : 0; // Fix page value if negative

    let currentPage = $('#bottom_page').val();

    if (fromScrollTop === true) {
        // Reverse page direction if fromScrollTop
        currentPage = parseInt($('#top_page').val());
    }

    output.current_page = parseInt(currentPage);

    output.nb_tot_prod = form.totalElems.value;

    if (output.page != 0) {
        output.nb_prod = nb_product;
    } else {
        // Since we are on the first page, we need to reset the number of products to the default value because pushs are set only on the first page and are repeated manually on others
        output.nb_prod = $('#nb_prod_default').val();
    }

    output.handle_push = 'false';

    output.navigationTypeIsScroll = isFrom ? true : false;
    output.tri = document.getElementById('choice_tri').value;

    /** LEGACY **/

    //Recuperation du trie par prix (descendant ou ascendant) qui passe, malheureusement, par l'url
    var url = document.location.href;
    var pattern = new RegExp("/t([0-9]*)(/m(0|1))*.html$");
    var pattern2 = new RegExp("/e([0-9]*).html$");
    var tri = "";
    // Si il y a un ordre dans les prix on charge l'id
    if (pattern.test(url)) {
        var res = url.match(pattern);
        var tri = res[1][0];
    }

    /** END LEGACY **/

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix =
            form.getValue("price_min", parseFloat) +
            "~" +
            form.getValue("price_max", parseFloat);
    }

    if (page === "all") {
        /** LEGACY **/

        var hash;

        if ((hash = location.hash && location.hash.match(/i=[0-9]+/g)[0])) {
            var marginTop = $(".banner_wrapper").height() + 20,
                $elem = hash ? $("#" + hash.replace("=", "")) : $list_item,
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;

            window.setTimeout(function() {
                $("#contents_wrapper").animate({scrollTop: top}, 0.8);
            }, 0);
        }

        /** END LEGACY **/
    } else {
        if (bloc_prod_xhr) {
            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + "ajax_reload_products.php",
            type: "get",
            data: output,
            success: function(res) {
                var old_elm = document.getElementById("list_item"),
                    content,
                    itm_length;

                if (
                    btn &&
                    btn.nextElementSibling &&
                    btn.nextElementSibling.classList.contains("btn_tunnel")
                ) {
                    btn.nextElementSibling.style.display = "none";
                }

                res = res.split("><><");

                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                if (content !== "") {

                    // This block of code updates the SEO-related link elements in the HTML document.
                    let canonical = res[5];
                    let prev = res[6];
                    let next = res[7];

                    $("link[rel^='canonical']").attr("href", path_root + canonical);
                    $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);

                    // spécifique à Kujten qui n'a que la langue EN en plus du fr
                    $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

                    let hrefPrev = $("link[rel^='prev']");
                    let hrefNext = $("link[rel^='next']");
                    let commentPrev = $('#href_prev');
                    let commentNext = $('#href_next');
                    
                    changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
                    changeSeoElement(next, hrefNext, commentNext, 'next');

                    if (!isNaN(page)) {
                        old_elm.setAttribute("data-p", page * nb_product);
                    }

                    (fromScrollBottom || fromScrollTop) ? '' : (old_elm.innerHTML = "");

                    document.getElementById("totalElems").value = itm_length;

                    if (sessionStorage.getItem('infiniteScroll') !== 'true' || (fromScrollTop !== true && page < nb_total_page)) {
                        paginate(page + 1);
                    }

                    if (window.lazyload !== undefined) {
                        lazyload.init();
                    }

                    //Si on était en haut de page, on rajoute en haut
                    if (fromScrollTop) {
                        var firstMsg = $('#list_item .item:first');
                        var curOffset = firstMsg.offset().top - $(document).scrollTop();
                        old_elm.insertAdjacentHTML('afterbegin', content);
                        $(document).scrollTop(firstMsg.offset().top-curOffset);
                        //L'inverse pour le bas
                    } else {
                        old_elm.insertAdjacentHTML('beforeend', content);
                    }

                    /** LEGACY **/

                    var js_compteur = $(".vf_countdown").length;
                    var js_encart = content.match(
                        /\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g
                    );

                    if (js_compteur != null) {
                        try {
                            var cnt_compteur = js_compteur.length;

                            for (var i = 0; i < cnt_compteur; i++) {
                                eval(
                                    js_compteur[i].replace(
                                        /(vf_compteur_begin|vf_compteur_end)/g,
                                        ""
                                    )
                                );
                            }
                        } catch (e) {}
                    }
                    if (js_encart != null) {
                        try {
                            js_encart = js_encart[0].replace(
                                /(\/\/vf_begin|\/\/vf_end)/g,
                                ""
                            );
                            eval(js_encart);
                        } catch (e) {}
                    }

                    /** END LEGACY **/
                } else {
                    content = '<div class="no_results_wrapper">' +
                        '<div class="search_details_wrapper">' +
                        '<div class="no_result_block">' +
                        Translator.translate('no_result_txt') +
                        '</div>' +
                        '</div>' +
                        '</div>';
                    old_elm.innerHTML = content;
                }
            },
            complete: function() {
                let maxItems = document.getElementById('totalElems').value;
                let productLoaded = $('#list_item .item:not(".push")').length;
                let pagerNav = document.querySelector('.pagerNav.end_pagi');
                var showSeeAll = 1;
                
                if (productLoaded == maxItems || productLoaded == 0) {
                    showSeeAll = 0;
                    $('.pagerNav.end_pagi .loader_scroll').hide(); // Hide "See all" button
                } else {
                    $('.pagerNav.end_pagi .loader_scroll').show();
                }

                $('.pagerNav.end_pagi #page_loader').hide();

                if ($("body.category").length) {
                    addClickTrigger(page+1);
                }

                if ($("#scroll_items #list_item .no_results_wrapper").length) {
                    // Hide pagination when no result
                    $('.pagerNav.end_pagi').hide();
                } else {
                    // Show pagination when result available
                    $('.pagerNav.end_pagi').show();
                }

                //Maintenant que tout est chargé, on débloque pour les autres pages s'il y en a
                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');
                    initInfiniteScroll(true);
                }

                if (tempo_reset) {
                    filterTrigger();
                    tempo_reset = false;
                }
            },
        });
    }
}

function addFilter(name,id) {
	$('#block_selected_filter').append(
        '<span data-obj_id="'+id+'" class="selected_filter bgSprite bfr cross">'+name+'</span>'
    );
	$('span[data-obj_id="'+id+'"]').on("click", deleteFilter);
}

function updateFilter(
    tab_response,
    page,
    parentPath,
    type,
    idObj,
    no_price_range_update
) {
    if (tab_response[0] != "") {
        var bloc_photo = tab_response[0];
        document.getElementById("list_item").innerHTML = bloc_photo;

        $(".slider_product_wrapper").each(function() {
            $(this).hover(
                function() {
                    $(this)
                        .children(".visu_product_slider")
                        .children(".visu_product_slider_hover")
                        .show();
                },
                function() {
                    $(this)
                        .children(".visu_product_slider")
                        .children(".visu_product_slider_hover")
                        .hide();
                }
            );
        });

        var nav = tab_response[2];
        var nav_bas = tab_response[3];

        var nb_prod = tab_response[4];
        // document.getElementById('nb_prod_choix').style.display = 'block';

        if (document.getElementById("nb_prod_numeric"))
            document.getElementById("nb_prod_numeric").innerHTML = nb_prod;
        if (document.getElementById("label_nb_prod_numeric")) {
            if (nb_prod == "")
                document.getElementById("label_nb_prod_numeric").style.display =
                    "none";
            else
                document.getElementById("label_nb_prod_numeric").style.display =
                    "block";
        }

        if (typeof page == "undefined") {
            var prix_min = tab_response[5];
            var prix_max = tab_response[6];

            if (!no_price_range_update) {
                $("#amount1").val(prix_min);
                $("#amount2").val(prix_max);
                $("#slider-range").slider("option", {
                    min: parseInt(prix_min),
                    max: parseInt(prix_max),
                    values: [parseInt(prix_min), parseInt(prix_max)],
                });
                min_price = prix_min;
                max_price = prix_max;

                $(".ui-slider-range").width("100%");
                $(".ui-slider-range").css("left", "0%");
                $(".ui-slider-range")
                    .next()
                    .css("left", "0%");
                $(".ui-slider-range")
                    .next()
                    .next()
                    .css("left", "100%");
            }

            // couleur
            var color = tab_response[7];
            var tab_color_tmp = color.split("~");
            var cnt_color = tab_color_tmp.length;

            if (tab_color_tmp[0] != "") {
                txt_color = ajax_file(
                    parentPath + "ajax_sprintf.php?arg1=recherche_couleurs"
                );
                var bloc_color =
                    '<div class="select_recherche">' +
                    txt_color +
                    '</div><div class="selected_options select_colors"></div>';

                var str_color = "";

                for (var j = 0; j < cnt_color; j++) {
                    var detail_color = tab_color_tmp[j].split("|");
                    var class_color = "btn_select_off";

                    if (tab_color.length > 0) {
                        if (in_array_js(detail_color[0], tab_color)) {
                            class_color = "btn_select";
                        }
                    }
                    str_color +=
                        '<div id="couleur_' +
                        detail_color[0] +
                        '" class="left_menu_obj ' +
                        class_color +
                        " couleur_" +
                        detail_color[0] +
                        '"><div class="f_left couleur_check"></div><div class="f_left txt_menu">' +
                        detail_color[1] +
                        /* ' (' + detail_color[2] + ')*/ "</div></div>";
                }

                $("#bloc_couleur")
                    .show()
                    .find(".selected_options")
                    .html(str_color);
            } else {
                $("#bloc_couleur")
                    .hide()
                    .find(".selected_options")
                    .html("");
            }
            // size
            var size = tab_response[8];

            var tab_size_tmp = size.split("~");
            var cnt_size = tab_size_tmp.length;

            if (tab_size_tmp[0] != "") {
                txt_size = ajax_file(
                    parentPath + "ajax_sprintf.php?arg1=recherche_taille"
                );

                var bloc_size =
                    '<div class="select_recherche">' +
                    txt_size +
                    '</div><div class="selected_options"></div>';

                var str_size = "";

                for (var j = 0; j < cnt_size; j++) {
                    var detail_size = tab_size_tmp[j].split("|");
                    var class_size = "btn_select_off";

                    if (tab_taille.length > 0) {
                        if (in_array_js(detail_size[0], tab_taille))
                            class_size = "btn_select";
                    }

                    str_size +=
                        '<div id="taille_' +
                        detail_size[0] +
                        '" class="left_menu_obj ' +
                        class_size +
                        '"><div class="f_left"></div><div class="f_left txt_menu">' +
                        detail_size[1] +
                        "</div></div>";
                }

                $("#bloc_taille")
                    .show()
                    .find(".selected_options")
                    .html(str_size);
                var windowW = $(window).height();
                if (windowW >= 900) {
                    $("#choix_recherche").sideBarEase("refresh");
                }
            } else {
                $("#bloc_taille")
                    .hide()
                    .find(".selected_options")
                    .html("");
            }
        }
    } else {
        $("#list_item").html(
            ajax_file(parentPath + "ajax_sprintf.php?arg1=no_response_search")
        );
        $("#pagination_haut").html("");
        $("#pagination_bas").html("");
    }
    $(".left_menu_obj").on("click touchend", filterBoxesToggle);
    var windowW = $(window).height();
    if (windowW >= 900) {
        $("#choix_recherche").sideBarEase("refresh");
    }
    $("#page").val(0);
}

function deleteFilter() {

    // If the filter is a price range, check and set the initial min and max price for the filter reset
	if ($(this).attr('data-obj_id') == 'priceRange') {
		$('#amount1').val($('#filter_price [name=initial_min_price]').val());
		$('#amount2').val($('#filter_price [name=initial_max_price]').val());
		// Reinit slider values and position
        $('#slider-range').slider("option", "values", [$('#filter_price [name=initial_min_price]').val(), $('#filter_price [name=initial_max_price]').val()]);
        $('#slider-range .ui-slider-handle:first .handler_price').html($('#filter_price [name=initial_min_price]').val());
        $('#slider-range .ui-slider-handle:last .handler_price').html($('#filter_price [name=initial_max_price]').val());
        generateNewBlocProd.call(document.getElementById('filter_sticky'));
	}
	else {
        $('.'+$(this).attr('data-obj_id')).click();
	}

}

function deleteAllFilter() {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.click();
            }
        } else if (elm.name == "filter_price") {
            $('span[data-obj_id="priceRange"]').click();
        }

    });
}

//On va regarde tout les bloc et on envoi l'ajax
//Pour les blocs de cases � cocher
//Pour le slider des prix
//Pour le bouton qui supprime un element
var bloc_search_xhr;

function generateNewBlocSearch(page, type, idObj, nb_product, btn) {
    var form =
            this instanceof HTMLFormElement
                ? this
                : document.getElementById("filter_sticky"),
        output = {},
        is_from_page = false,
        key;

    Array.prototype.slice.call(form.elements).forEach(function(elm) {
        if (elm.type === "checkbox" || elm.type === "radio") {
            if (elm.checked && elm.value !== "") {
                if (output[elm.name] === undefined) {
                    output[elm.name] = [];
                }

                output[elm.name].push(elm.value);
            }
        } else if (elm.type === "hidden") {
            if (elm.value !== "") {
                output[elm.name] = elm.value;
            }
        }
    });

    for (key in output) {
        if (output.hasOwnProperty(key)) {
            if (output[key].constructor === Array) {
                output[key] = output[key].join("~");
            }
        }
    }

    if (nb_product !== undefined) {
        form.nb_prod.value = nb_product;
    } else {
        nb_product = form.nb_prod.value;
    }

    if (page !== undefined && page !== "all") {
        form.page.value = page * nb_product;

        is_from_page = true;
    }

    output.page = form.page.value;
    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;

    // Si le prix existe
    if ($("#price_min").length > 0) {
        // Récupération du prix
        output.prix =
            form.getValue("price_min", parseFloat) +
            "~" +
            form.getValue("price_max", parseFloat);
    }

    if (bloc_search_xhr) {
        bloc_search_xhr.abort();
    }

    bloc_search_xhr = $.ajax({
        url: path_relative_root + "ajax_reload_search.php",
        type: "get",
        data: output,
        success: function(res) {
            var new_elm = document.createElement("div"),
                old_elm = document.getElementById("scroll_items"),
                content,
                itm_length;

            if (
                btn &&
                btn.nextElementSibling &&
                btn.nextElementSibling.classList.contains("btn_tunnel")
            ) {
                btn.nextElementSibling.style.display = "none";
            }

            res = res.split("><><");
            content = res[0].trim();

            size = res[8].trim();

            var array_size = size.split("~");

            itm_length = parseFloat(res[1]);

            if (content !== "") {
                new_elm.id = "list_item";
                new_elm.className = "items_wrapper";
                new_elm.innerHTML = content;

                if (!isNaN(page)) {
                    new_elm.setAttribute("data-p", page * nb_product);
                }

                old_elm.innerHTML = "";

                document.getElementById("nb_articles").innerHTML = itm_length;
                document.getElementById("totalElems").value = itm_length;

                if (page < nb_total_page) {
                    paginate(page + 1);
                }

                old_elm.appendChild(new_elm);

                if (window.lazyload !== undefined) {
                    lazyload.init();
                }

                /** LEGACY **/

                var js_compteur = $(".vf_countdown").length;
                var js_encart = content.match(
                    /\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g
                );

                if (js_compteur != null) {
                    try {
                        var cnt_compteur = js_compteur.length;

                        for (var i = 0; i < cnt_compteur; i++) {
                            eval(
                                js_compteur[i].replace(
                                    /(vf_compteur_begin|vf_compteur_end)/g,
                                    ""
                                )
                            );
                        }
                    } catch (e) {}
                }
                if (js_encart != null) {
                    try {
                        js_encart = js_encart[0].replace(
                            /(\/\/vf_begin|\/\/vf_end)/g,
                            ""
                        );
                        eval(js_encart);
                    } catch (e) {}
                }

                /** END LEGACY **/
            }
        },
        complete: function() {
            setTimeout(function() {
                $("html, body").animate({
                    scrollTop:  $("#content_obj").position().top - $("#site_head_wrap").height(),
                }, 200);
            }, 350);
        },
    });
}

// Affiche la liste des commandes selon le type
function show_orders_by_type(elementID, type, parentPath, reload) {

    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {

        str_year = '&year=' + year;
    }

    if (reload !== undefined) {

        str_reload = '&reload=true';
    }

    // Gestion de la surbrillance des onglets
    var i = 1;
    while (document.getElementById("onglet_" + i + "_mid")) {
        if (type == i) {
            $("#onglet_" + i + "_left").attr("class", "left-on");
            $("#onglet_" + i + "_mid").attr("class", "mid-on");
            $("#onglet_" + i + "_right").attr("class", "right-on");
        } else {
            $("#onglet_" + i + "_left").attr("class", "left-off");
            $("#onglet_" + i + "_mid").attr("class", "mid-off");
            $("#onglet_" + i + "_right").attr("class", "right-off");
        }
        i++;
    }

    var container = document.getElementById(elementID);

    //clearTimeout(time_out);
    if (container) {
        var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type + str_year + str_reload);

        if (response) {
            if (reload !== undefined) {
                $('.content_commandes').html(response);
            } else {

                $(container).html(response);
            }
            return true;
        }
        else{
            container.innerHTML = '';
            return false;
        }
    }
    else
        return false;
}

/**
 * Gestion de l'ouverture et de la fermeture des messages dans mon comptes (mes-message.php)
 */
function openCloseMessage(elem, msg_open, msg_close) {
    var id = elem.attr("id"),
        mId = id.substring(id.indexOf("_") + 1),
        messageContainer = $(".message_account_container");

    if (messageContainer.hasClass("appear")) {
        messageContainer.removeClass("appear");
    } else {
        messageContainer.addClass("appear");
    }

    if (!elem.hasClass("actif")) {
        $(".message_detail").slideUp();
        $(".btn_ouvrir_message")
            .not(elem)
            .removeClass("actif");
        $(".btn_ouvrir_message")
            .not(elem)
            .text(msg_open);

        if ($("#msg_txt_" + mId).html() == "") {
            $.ajax({
                type: "post",
                data: {mId: mId, type: 1},
                url: path_relative_root + create_link("message_detail"),
                success: function(response) {
                    $("#msg_txt_" + mId).html(response);
                    $("#msg_" + mId).slideDown();
                },
            });
        } else $("#msg_" + mId).slideDown();
        elem.addClass("actif");
        elem.text(msg_close);
    } else {
        $(".message_detail").slideUp();
        elem.removeClass("actif");
        elem.text(msg_open);
    }
}
function show_message(mId, hide) {
    if (hide == 0) {
        var ajax_content = ajax_file("ajax_show_messages.php?mId=" + mId);
        if (ajax_content) {
            document.getElementById("message_" + mId).innerHTML = ajax_content;

            document.getElementById("message_" + mId).onclick = "";
            document.getElementById("message_" + mId).style.cursor = "default";
        }
    } else {
        var ajax_content = ajax_file(
            "ajax_show_messages.php?mId=" + mId + "&hide=1"
        );
        if (ajax_content) {
            document.getElementById("message_" + mId).innerHTML = ajax_content;

            document.getElementById("message_" + mId).onclick =
                "show_message(" + mId + ",0);";

            document.getElementById("message_" + mId).style.cursor = "pointer";
        }
    }
}

var timer;
// --------------------------------------------------------------------------------
function newNewsletterFromCmpid() {
    var flag = verif_mail("email_inscription_home", "login_email");

    if (flag) {
        flag = ajax_file(
            path_relative_root +
                "ajax_email_newsletter_exist.php?mail=" +
                document.getElementById("email_inscription_home").value
        );

        var elementID = "email_inscription_home";

        var objElement = document.getElementById(elementID);
        var objLabel = document.getElementById(elementID + "Label");
        var erreurText = document.getElementById("erreur_msg");

        if (flag === "1") {
            flag = false;
        } else {
            flag = true;
        }

        if (!flag) {
            erreurText.innerHTML =
                '<p class="title_toolltip">' +
                ajax_file(path + "ajax_sprintf.php?arg1=login_email") +
                " :</p>" +
                "Cet email est déjà inscrit à la newsletter";
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
        } else {
            objElement.className = "inputForm";
            objElement.style.border = "1px solid #CCCCCC";
            objLabel.className = "labelForm";
        }
    }

    if (flag) {
        clearTimeout(timer);

        document.getElementById("bloc_erreur").style.display = "none";
        document.getElementById("img_error_popup").style.display = "none";
    } else {
        var val = document.getElementById("erreur_msg").innerHTML;
        var elem_img = document.getElementById("img_error_popup");
        $("#img_error_popup").css("display", "inline-block");

        elem_img.setAttribute("onmouseover", function() {
            OverPopup(this, val, "visible", "cmpid_adwords");
        });
        elem_img.onmouseover = function() {
            OverPopup(this, val, "visible", "cmpid_adwords");
        };
        elem_img.setAttribute("onmouseout", function() {
            OverPopup(this, val, "none", "cmpid_adwords");
        });
        elem_img.onmouseout = function() {
            OverPopup(this, val, "none", "cmpid_adwords");
        };

        elem = document.getElementById("email_inscription_home");

        elem.style.border = "1px solid red";
        pos = getPos(elem_img);

        document.getElementById("bloc_erreur").style.position = "absolute";

        var nav = navigator.appVersion;

        if (!/*@cc_on!@*/ (false || !!document.documentMode)) {
            $("#bloc_erreur").fadeIn(400);
        } else {
            document.getElementById("bloc_erreur").style.display = "block";
        }

        var clone = $("#bloc_erreur").clone(),
            c_pos = {
                top:
                    $("#img_error_popup").offset().top -
                    $("#img_error_popup")
                        .offsetParent()
                        .offset().top -
                    $("#bloc_erreur").height() +
                    4,
                left:
                    $("#img_error_popup").offset().left -
                    $("#img_error_popup")
                        .offsetParent()
                        .offset().left -
                    $("#bloc_erreur").innerWidth() +
                    $("#img_error_popup").width() * 2 +
                    6,
            };

        $("#bloc_erreur").remove();
        $("#img_error_popup").after(clone);

        $("#bloc_erreur")
            .find("td:nth-child(2)")
            .children("img")
            .css({
                float: "right",
                margin: "0 7px",
            });

        $("#bloc_erreur").css({
            position: "absolute",
            top: c_pos.top,
            left: c_pos.left + 271,
            "z-index": "99999",
            opacity: "1",
        });

        window.clearTimeout(timer);

        timer = setTimeout("closeInfobulle('bloc_erreur')", 5000);
    }

    return flag;
}

function checkAndAddMail() {
    if (newNewsletterFromCmpid()) {
        $("#btn_conf_cmpid").css("display", "none");
        $("#loader_popup").css("display", "block");

        response = ajax_file(
            path_relative_root +
                "ajax_add_email_newsletter.php?mail=" +
                document.getElementById("email_inscription_home").value +
                "&from=lightbox"
        );

        if (response == 1) {
            $("#formu_inscription_news").fadeOut(750, function() {
                $("#conf_inscription_news").fadeIn(750);
            });

            document.getElementById("shad").setAttribute("onclick", function() {
                closeMultiShad("popup_inscription_home");
            });
            document.getElementById("shad").onclick = function() {
                closeMultiShad("popup_inscription_home");
            };
        }
    }
}

function ajaxDeconnect(path_relative_root) {
    var provenance = window.location.pathname,
        cpt_client = new RegExp(
            /(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/
        ),
        response;

    // Get the current page's name
    provenance = provenance.substr(
        provenance.lastIndexOf("/"),
        provenance.lastIndexOf(".")
    );

    // This actually disconnects the client
    response = ajax_file(path_relative_root + "ajax_deconnect.php");

    // If there was an error
    if (!response) alert("Erreur lors de la déconnexion.");
    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href =
            path_relative_root + create_link("connexion_login");
    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;
}

/** wishlist **/
function addToWishlistShoppingcart() {
    $.post(path_relative_root + "ajax_cart_to_wishlist.php", function(resp) {
        $(".btn_top_wishlist .gris").html(
            "(x" + resp.result.wishlist_size + ")"
        );
        $("#addToWishlistShoppingcart")
            .removeClass("addToWishlistShoppingcart")
            .addClass("existsToWishlistShoppingcart");
        $("#addToWishlistShoppingcart").html(resp.result.button_label);
        $("#addToWishlistShoppingcart")
            .attr("onclick", "")
            .attr(
                "href",
                path_relative_root + create_link("client_index") + "#wl"
            );
    });
}

function deletePromoCode() {
    $.ajax({
        type: "get",
        url: path_relative_root + create_link("delete_promo_code"),
        success: function(response) {
            location.href = path_relative_root + create_link("order_kiala");
        },
    });
}

function ajax_RemoveBasketProd(basket_id, prod_id, qte) {
    $.ajax({
        type: "post",
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte,
        },
        url: path_relative_root + "ajax_removeBasketProd.php",
        success: function(res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            $("#cart_top .sub_menu_content").html(res.datas.html);
            $('#show_top_cart').addClass('appear');

            var count_bask = $('#cart_top .cart').attr('data-cart');

            if (count_bask > 3) {
                setTimeout(function() {
                    OverlayScrollbars(document.querySelector('#cart_list'),{});
                }, 500);
            }

            let getNewTemoin = $("#cart_top .sub_menu a.cart .rolloverTxt span:first-child").text();
            let getNewTemoinTrim = $.trim(getNewTemoin);
            
            if (getNewTemoinTrim.length < 4) {
                $("#cart_top .item_title.nb_basket").text(getNewTemoinTrim);
                
            } else {
                $("#cart_top .item_title.nb_basket").text(' ');
            }

            /* Filters Rayon */    
            lionBarOnFilter();
        },
    });
}

function sendMessage(id) {
    //disable the default form submission

    var mId = id;
    $(".loader_reponse_contact").css("display", "block");
    $("#envoyer_rep").addClass("active");

    if ($("#msg_txt_" + id).val() != "") {
        var upload_fail = ajax_file(
            "ajax_sprintf.php?arg1=contact_msg_pj_fail"
        );
        var formData = new FormData($("form#data")[0]);
        $.ajax({
            url: path_relative_root + create_link("message_detail"),
            type: "POST",
            data: formData,
            async: false,
            cache: false,
            contentType: false,
            processData: false,
            success: function(response) {
                if (response == "1") {
                    $("#msg_conf_" + id).html(
                        ajax_file("ajax_sprintf.php?arg1=contact_msg_envoyer")
                    );
                    $("#msg_conf_" + id).show();
                    $("#msg_conf_" + id).addClass("active");
                    $("#msg_txt_" + id).val(" ");
                    clearInput("filepj1");
                    $(".loader_reponse_contact").css("display", "none");
                    $("#envoyer_rep").addClass("active");
                    return false;
                } else if (response == "3") {
                    clearInput("filepj1");
                } else {
                    $("#msg_conf_" + mId).html(
                        ajax_file("ajax_sprintf.php?arg1=contact_msg_fail")
                    );
                    $("#msg_conf_" + mId).show();
                }
            },
        });
    } else {
        $(".loader_reponse_contact").css("display", "none");
        $("#contact_msg_text_vide")
            .css("color", "red")
            .fadeIn();
        setTimeout(function() {
            formMessageReset(id);
        }, 5000);
        $("#msg_conf_" + mId).hide();
    }
}

/*
 * Achat express utiliser pour les lookbook (sur les tag)
 */
function ajax_achat_express(reference, element) {
    var arg = "";
    var productUrl = element.attr("data-url");

    $.ajax({
        type: "get",
        url:
            path_relative_root +
            "ajax_achat_express.php?ref=" +
            reference +
            arg,
        success: function(response) {
            var $lightbox = $("#lightbox_achat_express");

            $("#content_achat_express").html(response);

            openMultiShad("lightbox_achat_express");

            // Pour les event des tooltips "Veuillez selectionner une taille"
            eventButton();
            setTimeout(function () {
                checkSubmitState();
            }, 500);

            if (!$(".w-alertestock-form").length && $('#keep_alive_stock').length) {
                new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
            }
        },
    });
}

function process_type_preco() {
    var val = $(".bloc_lightbox_preco.actif").attr("data-value");

    $.ajax({
        type: "post",
        data: {type_preco: val},
        url: path_relative_root + "ajax_choose_preco.php",
        success: function(response) {
            if (response.status == "success") {
                if ($("input[name=preco_choice]").length == 0) {
                    closeMultiShad("lightbox_preco");
                }
                var index_conveyor =
                    typeof array_transporteurs_datas[1] != "undefined" ? 1 : 31;

                $.each(response.transportation, function(i, item) {
                    array_transporteurs_datas[i]["fdp"][0] = item["unit"];
                    array_transporteurs_datas[i]["fdp"][1] = item["cent"];
                    array_transporteurs_datas[i]["fdp"]["format"] =
                        item["format"];
                    $(
                        ".choix_delivery[data-id-transporteur=" +
                            i +
                            "] .pricetag"
                    ).html(item["format"]);
                    $(
                        ".choix_delivery[data-id-transporteur=" +
                            i +
                            "] .price_tag"
                    ).html(item["format"]);
                });

                if (val == "several") {
                    $(".choix_delivery_relais").hide();
                    $(".choix_delivery_postal:first .choix_delivery").click();
                    $(".choix_delivery_postal:first .choix_livraison").attr(
                        "checked",
                        "checked"
                    );
                    $(".choix_delivery_postal:first .choix_livraison").change();
                } else {
                    $(".choix_delivery_relais").show();
                    $(".choix_delivery_relais:first").click();
                    $(".choix_delivery_relais:first .choix_livraison").attr(
                        "checked",
                        "checked"
                    );
                    $(".choix_delivery_relais:first .choix_livraison").change();
                }

                $("#tunnel_right_col #cart_total").css("visibility", "visible");
            }
        },
    });
}

function viewByTwo() {
    if ($('#list_item').length && $('#list_item').hasClass('bydefault')) {
        $('#list_item').removeClass('bydefault');
        $('#list_item').addClass('byTwo');
        $('#viewChanger .default').removeClass('actif');
        $('#viewChanger .byTwo').addClass('actif');
    }

    setTimeout(function () {
        $('.productSwiper').each(function () {
            if ($(this).hasClass('swiper-container-initialized')) {
                this.swiper.update();
            }
        });
    }, 400);
}

function viewDefault() {
    if ($('#list_item').length && $('#list_item').hasClass('byTwo')) {
        $('#list_item').removeClass('byTwo');
        $('#list_item').addClass('bydefault');
        $('#viewChanger .byTwo').removeClass('actif');
        $('#viewChanger .default').addClass('actif');
    }

    setTimeout(function () {
        $('.productSwiper').each(function () {
            if ($(this).hasClass('swiper-container-initialized')) {
                this.swiper.update();
            }
        });
    }, 400);
}

function rayonSizeUpdate() {
    setTimeout(function () {
        $('.productSwiper').each(function () {
            if ($(this).hasClass('swiper-container-initialized')) {
                this.swiper.update();
            }
        });
    }, 400);
}

$("#viewPerTwo, #viewPerThree").on("click", function() {
    rayonSizeUpdate() 
});

var tempo_reset = false;

/** Appel format prodfp < medium pour la vue/3 = meilleure performance */
function switchVisu() {

    var src_format = $('#list_item').length && $('#list_item').hasClass('view_per_three')? 'prodfp':'medium';
    var dest_format = $('#list_item').length && $('#list_item').hasClass('view_per_three')? 'medium':'medium';
    
    $('#list_item img').each(function () {
        if (typeof($(this).attr('src'))!='undefined') {
            $(this).attr('src',$(this).attr('src').replace(src_format,dest_format));
        }
    });
}

/***** RAYON + SEARCH VIEWS CHANGE *****/
$(function () {
    function view_changer() {
        $( '.views .view' ).click(function() {
            if ( ! $(this).hasClass('is-active') ) {
                $('.views .view').removeClass('is-active');
                $(this).addClass('is-active');
                switchVisu();
            }
        })

        $('#viewPerTwo').click(function() {
            if ( $('#list_item').hasClass('view_per_three') ) {
                $('#list_item').removeClass('view_per_three');
                createCookie('nb_produits', 2);
            }
            video_responsive_r();
        })

        $('#viewPerThree').click(function() {

            if ( ! $('#list_item').hasClass('view_per_three') ) {
                $('#list_item').addClass('view_per_three');
                createCookie('nb_produits', 3);
            }
            video_responsive_r();
        });
    }

    view_changer();
});

function createCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}
/**
 * Permet de calculer les mensualités d'un paiement Cofidis 3x
 * @param {string} amount 
 */
function calculCofidis3Cb()
{
    var amount = $('#montantCalcul').val();
    if (amount > 0) {
        $.ajax({
            url : 'https://www.simulateurcofidis.com/3cb/index.php',
            type : 'get',
            data : {'montant': amount, 'key': 'RlX3lf1f98Cal6r0v1ul7Phq7YfJSdnybnvuXuL6T9RaVGhRyr', 'defaut': 0, 'retour': 'flux'},
            success : function (response) {
                var resJson = JSON.parse(response);
                var montant = Number.parseFloat(resJson.montant).toFixed(2).toString().replace('.', ',');
                var mensualite = Number.parseFloat(resJson.mensualite).toFixed(2).toString().replace('.', ',');
                var derniere_mensulatie = Number.parseFloat(resJson.derniere_mensulatie).toFixed(2).toString().replace('.', ',');
                var montant_du = Number.parseFloat(resJson.montant_du).toFixed(2).toString().replace('.', ',');
                $('#tab_mensualites .montant .tab_amount span').html(montant);
                $('#tab_mensualites .mensualite_1 .tab_amount span').html(mensualite);
                $('#tab_mensualites .mensualite_2 .tab_amount span').html(derniere_mensulatie);
                $('#tab_mensualites .montant_du .tab_amount span').html(montant_du);
                $('#tab_mensualites .frais .tab_amount span').html(resJson.frais);
            }
        });
    }
}

function submitToCalcul(e) 
{
    if (e.keyCode && e.keyCode == "13") {
        calculCofidis3Cb();
    } else {
        return;
    }
}
